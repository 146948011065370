.login-page {
  text-align: center;
  padding: 20px;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: auto;

  >div {
    min-width: 350px;
    max-width: 500px;
    margin: auto;

    &:not(.not-center) {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    >img {
      width: 100%;
    }

    >div {
      margin-top: 30px;
      padding-top: 20px;
      border-top: solid 1px #bbb;
    }
  }
}

a {
  text-decoration: none;
  color: #0e6ac0;
}